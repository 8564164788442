import React from 'react'
import Layout from "../components/layout/index"
import Seo from "../components/seo"
import { LearnMoreComponent } from '../components/learn_more/index'

const LearnMore = () => {
  return (
    <Layout>
      <Seo title="Learn More" />
      <LearnMoreComponent text='Sign me up' link='/signup'/>
  </Layout>
  )
}

export default LearnMore